import React from "react"
import Provider from "./src/components/provider"
import Auth from "./src/components/auth"
import "moment/locale/id"
import "./src/styles/antd.css"

const auth = new Auth()

export const wrapRootElement = ({ element }) => (
  <Provider auth={auth}>{element}</Provider>
)
