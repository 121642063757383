// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/tmp/3603219a/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-callback-js": () => import("/tmp/3603219a/src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-customer-edit-js": () => import("/tmp/3603219a/src/pages/customer/edit.js" /* webpackChunkName: "component---src-pages-customer-edit-js" */),
  "component---src-pages-customer-index-js": () => import("/tmp/3603219a/src/pages/customer/index.js" /* webpackChunkName: "component---src-pages-customer-index-js" */),
  "component---src-pages-customer-new-js": () => import("/tmp/3603219a/src/pages/customer/new.js" /* webpackChunkName: "component---src-pages-customer-new-js" */),
  "component---src-pages-index-js": () => import("/tmp/3603219a/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-item-category-edit-js": () => import("/tmp/3603219a/src/pages/item-category/edit.js" /* webpackChunkName: "component---src-pages-item-category-edit-js" */),
  "component---src-pages-item-category-index-js": () => import("/tmp/3603219a/src/pages/item-category/index.js" /* webpackChunkName: "component---src-pages-item-category-index-js" */),
  "component---src-pages-item-category-new-js": () => import("/tmp/3603219a/src/pages/item-category/new.js" /* webpackChunkName: "component---src-pages-item-category-new-js" */),
  "component---src-pages-item-edit-js": () => import("/tmp/3603219a/src/pages/item/edit.js" /* webpackChunkName: "component---src-pages-item-edit-js" */),
  "component---src-pages-item-index-js": () => import("/tmp/3603219a/src/pages/item/index.js" /* webpackChunkName: "component---src-pages-item-index-js" */),
  "component---src-pages-item-new-js": () => import("/tmp/3603219a/src/pages/item/new.js" /* webpackChunkName: "component---src-pages-item-new-js" */),
  "component---src-pages-marketplace-edit-js": () => import("/tmp/3603219a/src/pages/marketplace/edit.js" /* webpackChunkName: "component---src-pages-marketplace-edit-js" */),
  "component---src-pages-marketplace-index-js": () => import("/tmp/3603219a/src/pages/marketplace/index.js" /* webpackChunkName: "component---src-pages-marketplace-index-js" */),
  "component---src-pages-marketplace-new-js": () => import("/tmp/3603219a/src/pages/marketplace/new.js" /* webpackChunkName: "component---src-pages-marketplace-new-js" */),
  "component---src-pages-report-stock-js": () => import("/tmp/3603219a/src/pages/report/stock.js" /* webpackChunkName: "component---src-pages-report-stock-js" */),
  "component---src-pages-stock-in-edit-js": () => import("/tmp/3603219a/src/pages/stock-in/edit.js" /* webpackChunkName: "component---src-pages-stock-in-edit-js" */),
  "component---src-pages-stock-in-index-js": () => import("/tmp/3603219a/src/pages/stock-in/index.js" /* webpackChunkName: "component---src-pages-stock-in-index-js" */),
  "component---src-pages-stock-in-new-js": () => import("/tmp/3603219a/src/pages/stock-in/new.js" /* webpackChunkName: "component---src-pages-stock-in-new-js" */),
  "component---src-pages-stock-out-edit-js": () => import("/tmp/3603219a/src/pages/stock-out/edit.js" /* webpackChunkName: "component---src-pages-stock-out-edit-js" */),
  "component---src-pages-stock-out-index-js": () => import("/tmp/3603219a/src/pages/stock-out/index.js" /* webpackChunkName: "component---src-pages-stock-out-index-js" */),
  "component---src-pages-stock-out-new-js": () => import("/tmp/3603219a/src/pages/stock-out/new.js" /* webpackChunkName: "component---src-pages-stock-out-new-js" */),
  "component---src-pages-supplier-edit-js": () => import("/tmp/3603219a/src/pages/supplier/edit.js" /* webpackChunkName: "component---src-pages-supplier-edit-js" */),
  "component---src-pages-supplier-index-js": () => import("/tmp/3603219a/src/pages/supplier/index.js" /* webpackChunkName: "component---src-pages-supplier-index-js" */),
  "component---src-pages-supplier-new-js": () => import("/tmp/3603219a/src/pages/supplier/new.js" /* webpackChunkName: "component---src-pages-supplier-new-js" */)
}

