import ApolloClient from "apollo-boost"
import IdTokenVerifier from "idtoken-verifier"
import React from "react"
import { ApolloProvider } from "react-apollo"
import AuthContext from "./auth-context"

const client = new ApolloClient({
  uri: process.env.API_URI,
  request: async operation => {
    const token = localStorage.getItem("access_token")
    const { payload } = new IdTokenVerifier().decode(token)
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
        "x-hasura-role": payload["https://mybabytools.com/roles"][0] || "", // FIXME: P0 figure out a way to prevent role from being bypassed
      },
    })
  },
})

const Provider = ({ auth, children }) => (
  <ApolloProvider client={client}>
    <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
  </ApolloProvider>
)

export default Provider
