import auth0 from "auth0-js"

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: "gradeus.auth0.com",
    clientID: process.env.AUTH_CLIENT_ID,
    redirectUri: process.env.AUTH_REDIRECT_URI,
    audience: process.env.AUTH_AUDIENCE,
    responseType: "token id_token",
    scope: "openid profile email",
  })

  constructor() {
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
  }

  login(options) {
    this.auth0.authorize(options)
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult)
          resolve(authResult)
        } else if (err) {
          reject(err)
        }
      })
    })
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    )
    localStorage.setItem("access_token", authResult.accessToken)
    localStorage.setItem("id_token", authResult.idToken)
    localStorage.setItem("expires_at", expiresAt)
    localStorage.setItem("id_token:sub", authResult.idTokenPayload.sub)
    // localStorage.setItem("roles", authResult.accessTokenPayload)
  }

  logout() {
    // Clear access token and ID to̵ken from local storage
    localStorage.removeItem("access_token")
    localStorage.removeItem("id_token")
    localStorage.removeItem("expires_at")
    localStorage.removeItem("id_token:sub")
    // navigate to the home route
    this.auth0.logout()
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"))
    return new Date().getTime() < expiresAt
  }
}
